<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Trading information
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="UTR number"
          label-for="utr-number-edit"
        >
          <b-form-input
            v-model="utr"
            placeholder="1234567890"
            name="utr-number-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="worker-type-radio">
          <template v-slot:label>
            <span class="required-field">Worker type</span>
          </template>
          <b-form-radio-group
            v-model="companyType"
            :options="workerTypeOptions"
            name="worker-type-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            :state="companyTypeState"
            required
            class="mt-1"
          />
          <b-form-invalid-feedback :state="companyTypeState">
            Select a worker type
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Are you VAT registered?"
          label-for="vat-reg-radio"
          required
        >
          <b-form-radio-group
            v-model="vatReg"
            :options="yesNoOptions"
            name="vat-reg-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Trading name"
          label-for="trading-name-edit"
        >
          <b-form-input
            v-model="tradeName"
            name="trading-name-edit"
            class="text-uppercase"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="company-reg-no-edit">
          <template v-slot:label>
            <span :class="companyTypeFieldsRequired ? 'required-field':''">Company registration number</span>
          </template>
          <b-form-input
            v-model="companyRegNo"
            placeholder=""
            name="company-reg-no-edit"
            :disabled="companyTypeFieldsRequired !== true"
            :required="companyTypeFieldsRequired"
            :state="companyRegState"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="company-vat-no-edit">
          <template v-slot:label>
            <span :class="vatReg ? 'required-field':''">Vat number</span>
          </template>
          <b-form-input
            v-model="compVatNo"
            placeholder=""
            name="company-vat-no-edit"
            :disabled="vatReg !== true"
            :required="vatReg"
            :state="VatNoState"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="trades-option">
          <template v-slot:label>
            <span class="required-field">Trades</span>
          </template>
          <b-form-select
            v-model="selectedTrade"
            name="trades-option"
            :state="tradeState"
          >
            <b-form-select-option
              v-for="trd in trades"
              :key="trd.trade_id"
              :value="trd.trade_id"
            >
              {{ trd.trade }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback :state="tradeState">
            Select a trade
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Day rate"
          label-for="day-rate-edit"
        >
          <b-form-input
            v-model="dayRate"
            name="day-rate-edit"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Tax rate"
          label-for="tax-rate-edit"
        >
          <b-form-input
            v-model="taxRate"
            placeholder=""
            name="tax-rate-edit"
            :state="taxState"
          />
          <b-form-invalid-feedback :state="taxRate !== '' && taxRate !== 0">
            Enter a valid tax rate
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormSelect, BFormRadioGroup, BFormSelectOption, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'TradingInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BFormRadioGroup,
    BFormSelectOption,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dayRate: 0.00,
      dateLastProcessed: '',
      yesNoOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
      workerTypeOptions: [
        { item: 'soletrader', name: 'Sole trader' },
        { item: 'ltd', name: 'Limited company' },
      ],
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    ...mapGetters({ operativeValidation: 'app/getOperativeValidation' }),
    ...mapGetters({ trades: 'app/getTrades' }),
    tradeName: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].tradename
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeTradeName', value)
      },
    },
    tradeState() {
      if (this.operativeValidation == null) return null

      if (this.selectedTrade === null || this.selectedTrade === undefined || this.selectedTrade < 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    selectedTrade: {
      get() {
        if (this.opInfo.trade_id === undefined) return -1
        return this.opInfo.trade_id
      },
      set(value) {
        this.$store.commit('app/setOperativeTradeId', value)
        this.updateRate(value)
      },
    },
    vatReg: {
      get() {
        return this.opInfo.operativedetails[0].vatreg
      },
      set(value) {
        this.$store.commit('app/setOperativeVatReg', value)
      },
    },
    VatNoState() {
      if (!this.vatReg) return null

      if (this.operativeValidation == null) return null

      if (this.compVatNo === null || this.compVatNo === undefined || this.compVatNo.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    compVatNo: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].vatno
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeVatNo', value)
      },
    },
    utr: {
      get() {
        if (this.opInfo.cisdetails[0] !== undefined) {
          return this.opInfo.cisdetails[0].utr
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeUtr', value)
      },
    },
    companyTypeFieldsRequired() {
      if (this.companyType === undefined || this.companyType === 0 || this.companyType === 'soletrader') {
        return false
      }
      return true
    },
    companyTypeState() {
      if (this.operativeValidation == null) return null

      if (this.companyType === null || this.companyType === undefined || this.companyType.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    companyType: {
      get() {
        return this.opInfo.comptype
      },
      set(value) {
        this.$store.commit('app/setOperativeCompanyType', value)
      },
    },
    taxState() {
      return this.taxRate.length > 0
    },
    taxRate: {
      get() {
        if (this.opInfo.cisdetails[0] !== undefined) {
          return this.opInfo.cisdetails[0].taxrate
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeTaxRate', value)
      },
    },
    companyRegState() {
      if (!this.companyTypeFieldsRequired) return null

      if (this.operativeValidation == null) return null

      if (this.companyRegNo === null || this.companyRegNo === undefined || this.companyRegNo.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    companyRegNo: {
      get() {
        return this.opInfo.comp_reg_no
      },
      set(value) {
        this.$store.commit('app/setOperativeCompanyRegNo', value)
      },
    },
    validation() {
      if (this.companyType === '') return false

      return true
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
  },
  methods: {
    updateRate(tradeId) {
      if (this.trades === undefined) return

      const tradeIndex = this.trades.findIndex(trade => trade.trade_id === tradeId)
      if (tradeIndex < 0) {
        this.dayRate = 0.00
      } else {
        this.dayRate = this.trades[tradeIndex].rate
      }
      this.$store.commit('app/setOperativeTradeRate', this.dayRate)
    },
  },
}
</script>
