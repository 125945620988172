<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        SCL Services
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Subscriptions"
          label-for="scl-services"
        >
          <div class="scl-services">
            <b-form-checkbox
              id="invoice-service-checkbox"
              v-model="invoiceService"
              class="mr-1"
            >
              Invoicing
            </b-form-checkbox>
            <b-form-checkbox
              id="tax-service-checkbox"
              v-model="taxService"
            >
              Tax return
            </b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Fee override"
          label-for="weekly-charge-edit"
        >
          <b-form-input
            v-model="feeoverride"
            placeholder=""
            name="weekly-charge-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Last payment processed"
          label-for="date-last-processed-edit"
        >
          <b-form-input
            v-model="dateLastProcessed"
            placeholder=""
            name="date-last-processed-edit"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Select Year"
          label-for="year"
        >
          <b-form-select
            id="year"
            v-model="selectedYear"
            name="year"
            :value="selectedYear"
            @change="updateFeeTotals"
          >
            <b-form-select-option
              v-for="yr in searchYears"
              :key="yr.taxyear_id"
              :value="yr.taxyear_id"
            >
              {{ yr.period }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Invoice fees"
          label-for="fees-for-year-edit"
        >
          <b-form-input
            v-model="feesForYear"
            placeholder=""
            name="fees-for-year-edit"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Tax fees"
          label-for="tax-for-year-edit"
        >
          <b-form-input
            v-model="taxForYear"
            placeholder=""
            name="tax-for-year-edit"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormCheckbox, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'SCLServicesInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateLastProcessed: '',
      selectedYear: -1,
      yesNoOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
      serviceOptions: [
        { item: 'invoicing', name: 'Invoicing' },
        { item: 'taxreturn', name: 'Tax Return' },
      ],
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    ...mapGetters({ searchYears: 'app/getSearchYears' }),
    ...mapGetters({ taxForYear: 'app/getTaxFeeTotal' }),
    ...mapGetters({ feesForYear: 'app/getOtherFeesTotal' }),
    invoiceService: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].sclinv
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeInvoiceService', value)
        // Need to update Sage Sync IDs
        this.$store.commit('app/setSyncSageValues')
      },
    },
    taxService: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].scltax
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeTaxService', value)
        // Need to update Sage Sync IDs
        this.$store.commit('app/setSyncSageValues')
      },
    },
    feeoverride: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].feeoverride
        }
        return 0
      },
      set(value) {
        this.$store.commit('app/setOperativeFeeOverride', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
    this.$store.dispatch('app/getSearchYears')
  },
  methods: {
    updateFeeTotals() {
      const params = { ent_id: this.opInfo.ent_id, taxyear_id: this.selectedYear }
      this.$store.dispatch('app/getFeeTotalsForCurrentClient', params)
    },
  },
}
</script>

<style scoped>
.scl-services {
  margin-top: 0.5rem;
  display: flex;
}
</style>
