<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Personal information
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Title"
          label-for="title-option"
        >
          <b-form-select
            v-model="title"
            name="title-option"
            :options="titleOptions"
            class="text-uppercase"
            value-field="item"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="forename-edit">
          <template v-slot:label>
            <span class="required-field">Firstname</span>
          </template>
          <b-form-input
            v-model="firstname"
            name="forename-edit"
            class="text-uppercase"
            :state="firstnameState"
            required
            placeholder="Joe"
            @keydown="validateInput"
          />
          <b-form-invalid-feedback :state="firstnameState">
            Enter a firstname
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="surname-edit">
          <template v-slot:label>
            <span class="required-field">Lastname</span>
          </template>
          <b-form-input
            v-model="lastname"
            validate="'required'"
            name="surname-edit"
            class="text-uppercase"
            :state="lastnameState"
            required
            placeholder="Bloggs"
            @keydown="validateInput"
          />
          <b-form-invalid-feedback :state="lastnameState">
            Enter a lastname
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="dob-date">
          <template v-slot:label>
            <span class="required-field">Date of birth</span>
          </template>
          <b-form-datepicker
            id="dob-date"
            v-model="dateOfBirth"
            selected-variant="primary"
            show-decade-nav
            reset-button
            close-button
            locale="en-GB"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            :state="dobState"
            required
            placeholder=""
          />
          <b-form-invalid-feedback :state="dobState">
            Enter a date of birth
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="N.I. number."
          label-for="nino-edit"
        >
          <b-form-input
            v-model="niNumber"
            class="text-uppercase"
            placeholder=""
            name="nino-edit"
            :state="niState"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Nationality"
          label-for="nationality-option"
        >
          <b-form-select
            v-model="nationality"
            name="nationality-option"
            :options="countryOptions"
            class="text-uppercase"
            value-field="code"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Worker permit"
          label-for="work-permit-radio"
        >
          <b-form-radio-group
            v-model="workPermit"
            :options="workPermitOptions"
            name="work-permit-radio"
            class="mt-1"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          />
        </b-form-group>
      </b-col>
      <b-col
        sm="4"
        class="pr-1 mr-0"
      >
        <b-form-group label-for="CSCS-card-radio">
          <template v-slot:label>
            <span>Do you have a CSCS card?</span>
          </template>
          <b-form-radio-group
            v-model="cscsCard"
            :options="cscsCardOptions"
            name="CSCS-card-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Right to work"
          label-for="right-to-work"
        >
          <div class="right-to-work">
            <b-form-checkbox
              id="rtwapprove-checkbox"
              v-model="rtwApproved"
              class="mr-1"
            >
              Approved
            </b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormSelect, BFormDatepicker, BFormRadioGroup, BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { generalMethods } from '@/mixins/generic-functions'

export default {
  name: 'PersonalInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BFormDatepicker,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      workPermitOptions: [
        { item: 'Yes', name: 'Yes' },
        { item: 'No', name: 'No' },
        { item: 'Not required', name: 'Not required' },

      ],
      cscsCardOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
      countryOptions: generalMethods.getCountryOptions(),
      titleOptions: generalMethods.getTitleOptions(),
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    ...mapGetters({ operativeValidation: 'app/getOperativeValidation' }),
    title: {
      get() {
        return this.opInfo.title
      },
      set(value) {
        this.$store.commit('app/setOperativeTitle', value)
      },
    },
    firstnameState() {
      if (this.operativeValidation == null) return null

      if (this.firstname === null || this.firstname === undefined || this.firstname.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    firstname: {
      get() {
        return this.opInfo.firstname
      },
      set(value) {
        this.$store.commit('app/setOperativeFirstname', value)
      },
    },
    lastnameState() {
      if (this.operativeValidation == null) return null

      if (this.lastname === null || this.lastname === undefined || this.lastname.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    lastname: {
      get() {
        return this.opInfo.lastname
      },
      set(value) {
        this.$store.commit('app/setOperativeLastname', value)
      },
    },
    dobState() {
      if (this.operativeValidation == null) return null

      if (this.dateOfBirth === null || this.dateOfBirth === undefined || this.dateOfBirth.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    dateOfBirth: {
      get() {
        // New operative setup, so this will enforce a DOB
        if (this.opInfo.dob === 'new') {
          return ''
        }
        // Existing operatives will have null if no date set, to prevent it being compulsory setting blank date
        if (this.opInfo.dob === null) {
          return '    -  -  '
        }
        return this.opInfo.dob
      },
      set(value) {
        this.$store.commit('app/setOperativeDateOfBirth', value)
      },
    },
    niState() {
      if (this.niNumber === null || this.niNumber === undefined) {
        return false
      }
      return this.opInfo.ninumber.length > 0
    },
    niNumber: {
      get() {
        return this.opInfo.ninumber
      },
      set(value) {
        this.$store.commit('app/setOperativeNINumber', value)
      },
    },
    nationality: {
      get() {
        return this.opInfo.nationality
      },
      set(value) {
        this.$store.commit('app/setOperativeNationality', value)
      },
    },
    workPermit: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].workpermit
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeWorkPermit', value)
      },
    },
    cscsCard: {
      get() {
        return this.opInfo.operativedetails[0].cscscard
      },
      set(value) {
        this.$store.commit('app/setApplicationCSCS', value)
      },
    },
    rtwApproved: {
      get() {
        return this.opInfo.operativedetails[0].rtwapproved
      },
      set(value) {
        this.$store.commit('app/setOperativeRTWApproved', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
  },
  methods: {
    // dobOnContext(ctx) {
    //   // The date formatted in the locale, or the `label-no - date - selected` string
    //   // this.formatted = ctx.selectedFormatted
    //   // The following will be an empty string until a valid date is entered
    //   // this.selected = ctx.selectedYMD
    // },
    validateInput(event) {
      const pattern = /^[a-zA-Z]*$/ // Regular expression pattern allowing alphanumeric characters
      if (!pattern.test(event.key)) {
        event.preventDefault() // Prevent the special character from being inserted
      }
      if (/[%£$^*"~#,.0-9]/.test(event.key)) {
        this.firstname = this.firstname.replace(/[%£$^*"~#,.0-9]/g, '')
        this.lastname = this.lastname.replace(/[%£$^*"~#,.0-9]/g, '')
      }
    },
  },
}
</script>

<style scoped>
.right-to-work {
  margin-top: 0.5rem;
  display: flex;
}
</style>
