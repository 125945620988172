<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Address
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Address line 1"
          label-for="address-1-edit"
        >
          <b-form-input
            v-model="addressLine1"
            class="text-uppercase"
            placeholder="25 Yellow Brick Road"
            name="address-1-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Address line 2"
          label-for="address-2-edit"
        >
          <b-form-input
            v-model="addressLine2"
            class="text-uppercase"
            placeholder="Wimbledon"
            name="address-2-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="City"
          label-for="city-edit"
        >
          <b-form-input
            v-model="city"
            class="text-uppercase"
            placeholder="London"
            name="city-edit"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
      <b-col sm="4">
        <b-form-group
          label="Post code"
          label-for="postcode-edit"
        >
          <b-form-input
            v-model="postcode"
            class="text-uppercase"
            name="postcode-edit"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <!-- <b-col sm="4">
        <b-form-group
          label="County"
          label-for="county-edit"
        >
          <b-form-input
            v-model="county"
            placeholder="County"
            name="County-edit"
          />
        </b-form-group>
      </b-col> -->
      <!-- <b-col sm="4">
        <b-form-group
          label="Country"
          label-for="country-edit"
        >
          <b-form-input
            v-model="country"
            name="country-edit"
            placeholder="Country"
          />
        </b-form-group>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'AddressInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    addressLine1: {
      get() {
        return this.opInfo.addressLine1
      },
      set(value) {
        this.$store.commit('app/setOperativeAddressLine1', value)
      },
    },
    addressLine2: {
      get() {
        return this.opInfo.addressLine2
      },
      set(value) {
        this.$store.commit('app/setOperativeAddressLine2', value)
      },
    },
    city: {
      get() {
        return this.opInfo.city
      },
      set(value) {
        this.$store.commit('app/setOperativeCity', value)
      },
    },
    postcode: {
      get() {
        return this.opInfo.postcode
      },
      set(value) {
        this.$store.commit('app/setOperativePostcode', value)
      },
    },
    // county: {
    //   get() {
    //     return this.opInfo.county
    //   },
    //   set(value) {
    //     this.$store.commit('app/setOperativeCounty', value)
    //   },
    // },
    // country: {
    //   get() {
    //     return this.opInfo.country
    //   },
    //   set(value) {
    //     this.$store.commit('app/setOperativeCountry', value)
    //   },
    // },
  },
}
</script>

<style scoped>

</style>
