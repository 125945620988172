<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Private information
      </h2>
      <b-col />
      <b-col v-if="operativeId > -1">
        <div class="float-right">
          <b-button
            v-if="opInfo.status == 'ACCEPTED'"
            variant="primary"
            block
            class="button-size"
            @click="onApproveOperative"
          >
            Approve
          </b-button>
          <b-button
            v-else
            variant="primary"
            block
            class="button-size"
            @click="onSaveOperative"
          >
            Save
          </b-button>
        </div>
        <div class="float-right mr-2">
          <b-button
            variant="outline-secondary"
            block
            class="button-size"
            @click="onBackToPaymentsClick"
          >
            Cancel
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="scl-id-edit">
          <template v-slot:label>
            <span class="required-field">SCL ID</span>
          </template>
          <b-form-input
            v-model="sclId"
            name="scl-id-edit"
            :state="sclIdState"
            @click="recordCurrentSclId"
            @blur="updateBacsRef"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-form-select
            id="status"
            v-model="status"
            name="status"
          >
            <b-form-select-option value="ACTIVE">
              Active
            </b-form-select-option>
            <b-form-select-option value="INACTIVE">
              Inactive
            </b-form-select-option>
            <!-- <b-form-select-option
              v-if="opInfo.status != 'ACTIVE' && opInfo.status != 'INACTIVE'"
              value="APPROVED"
            >
              Approved
            </b-form-select-option> -->
            <b-form-select-option
              v-if="opInfo.status != 'ACTIVE' && opInfo.status != 'INACTIVE'"
              value="ACCEPTED"
            >
              Accepted
            </b-form-select-option>
            <b-form-select-option
              v-if="opInfo.status != 'ACTIVE' && opInfo.status != 'INACTIVE'"
              value="REJECTED"
            >
              Rejected
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Client last worked for"
          label-for="client-option"
        >
          <b-form-select
            v-model="selectedClient"
            name="client-option"
            :state="clientState"
          >
            <b-form-select-option
              v-for="client in searchClients"
              :key="client.ent_id"
              :v-bind="client.ent_id"
              :value="client.ent_id"
            >
              {{ client.name }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback :state="selectedClient > -1">
            Select a client
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          label="Sage CID"
          label-for="client-id-edit"
          hidden
        >
          <b-form-input
            v-model="clientSageId"
            name="client-id-edit"
            disabled
            hidden
          />
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          label="Sage SID"
          label-for="supplier-id-edit"
          hidden
        >
          <b-form-input
            v-model="supplierSageId"
            name="supplier-id-edit"
            disabled
            hidden
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <b-form-group
          label="GDPR signed"
          label-for="gdpr-signed-switch"
        >
          <b-form-checkbox
            v-model="gdprSigned"
            name="gdpr-signed-switch"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          label="CIS"
          label-for="cis-reg-switch"
        >
          <b-form-checkbox
            v-model="cisReg"
            name="cis-reg-switch"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          label="Link to Sage"
          label-for="sage-export-switch"
        >
          <b-form-checkbox
            v-model="sageExportEnable"
            name="sage-export-switch"
            switch
            inline
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="job-source-option">
          <template v-slot:label>
            <span class="required-field">Source</span>
          </template>
          <b-form-select
            v-model="selectedJobSource"
            name="job-source-option"
            :state="jobsourceState"
          >
            <b-form-select-option
              v-for="src in jobSources"
              :key="src.jobsource_id"
              :value="src.jobsource_id"
            >
              {{ src.sourcename }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback :state="jobsourceState">
            Select a source
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormCheckbox, BFormSelect, BFormSelectOption, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { generalMethods } from '@/mixins/generic-functions'

export default {
  name: 'PrivateInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    creatingNew: {
      type: Boolean,
      required: true,
    },
    operativeId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      currentSclId: '',
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    ...mapGetters({ operativeValidation: 'app/getOperativeValidation' }),
    ...mapGetters({ searchClients: 'app/getSearchClients' }),
    ...mapGetters({ jobSources: 'app/getJobsources' }),
    sclIdState() {
      if (this.applicationValidation == null) return null

      if (this.sclId === null || this.sclId === undefined || this.sclId.length === 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    sclId: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].sage_id
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeSageId', value)
        // Need to update Sage Sync IDs
        this.$store.commit('app/setSyncSageValues')
      },
    },
    status: {
      get() {
        if (this.opInfo.status !== undefined) {
          return this.opInfo.status
        }
        return -1
      },
      set(value) {
        this.$store.commit('app/setOperativeStatus', value)
      },
    },
    clientSageId: {
      get() {
        if (this.opInfo.entitysync === undefined || this.opInfo.entitysync[1] === undefined) {
          return ''
        }
        return this.opInfo.entitysync[1].sync_id
      },
      set() {
        // Need to update Sage Sync IDs
        this.$store.commit('app/setSyncSageValues')
      },
    },
    supplierSageId: {
      get() {
        if (this.opInfo.entitysync === undefined || this.opInfo.entitysync[0] === undefined) {
          return ''
        }
        return this.opInfo.entitysync[0].sync_id
      },
      set() {
        // Need to update Sage Sync IDs
        this.$store.commit('app/setSyncSageValues')
      },
    },
    gdprSigned: {
      get() {
        if (this.opInfo.gdprdetails[0] !== undefined) {
          return this.opInfo.gdprdetails[0].auth
        }
        return false
      },
      set(value) {
        if (value === null) {
          this.$store.commit('app/setOperativeGdprSigned', false)
        }
        this.$store.commit('app/setOperativeGdprSigned', value)
      },
    },
    cisReg: {
      get() {
        if (this.opInfo.cisdetails[0] !== undefined) {
          if (this.opInfo.cisdetails[0].cisreg === null) return false

          return this.opInfo.cisdetails[0].cisreg
        }
        return false
      },
      set(value) {
        if (value === null) {
          this.$store.commit('app/setOperativeCisReg', false)
        }
        this.$store.commit('app/setOperativeCisReg', value)
      },
    },
    sageExportEnable: {
      get() {
        return this.opInfo.sage_exp_enable
      },
      set(value) {
        if (value === null) {
          this.$store.commit('app/setOperativeSageExportEnable', false)
        }
        this.$store.commit('app/setOperativeSageExportEnable', value)
        // Need to update Sage Sync IDs
        if (value === true) {
          this.$store.commit('app/setSyncSageValues')
        }
      },
    },
    clientState() {
      return this.selectedClient > -1
    },
    selectedClient: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].client_ent_id
        }
        return -1
      },
      set(value) {
        this.$store.commit('app/setOperativeClientId', value)
      },
    },
    jobsourceState() {
      if (this.operativeValidation == null) return null

      if (this.selectedJobSource === null || this.selectedJobSource === undefined || this.selectedJobSource < 0) {
        this.$store.commit('app/failOperativeValidation')
        return false
      }
      return true
    },
    selectedJobSource: {
      get() {
        if (this.opInfo.jobsource_id_id === undefined || this.opInfo.jobsource_id_id === null) return -1
        return this.opInfo.jobsource_id_id
      },
      set(value) {
        this.$store.commit('app/setOperativeJobsourceId', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
    this.$store.dispatch('app/getJobsources')
  },
  methods: {
    recordCurrentSclId() {
      this.currentSclId = this.sclId
    },
    updateBacsRef() {
      if (this.opInfo.bankdetails === undefined) return
      if (`SUPPLY CONS ${this.currentSclId}` !== this.opInfo.bankdetails[0].bacsref && this.opInfo.bankdetails[0].bacsref !== '') return
      this.$store.commit('app/setOperativeBacsRef', `SUPPLY CONS ${this.sclId}`)
    },
    onApproveOperative() {
      this.$store.commit('app/approveCurrentOperative')
      this.onSaveOperative()
    },
    async doChecks() {
      this.$store.commit('app/startOperativeValidation')
      await new Promise(resolve => setTimeout(resolve, 1000))
      // if (this.operativeId > -1) {
      if (!this.operativeValidation) {
        generalMethods.failedMessage('To save the operative all required fields must be filled')
        return false
      }
      return true
    },
    async onSaveOperative() {
      if (!await this.doChecks()) {
        return
      }
      this.$store.dispatch('app/saveCurrentOperative', { operativeid: this.operativeId })
      // }
    },
    onBackToPaymentsClick() {
      this.$router.go(-1) // Return to previous page
    },
  },
}
</script>

<style scoped>
.button-size {
  width: 104px;
}
</style>
