<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Bank details
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Account holder name and surname"
          label-for="account-holder-name-edit"
        >
          <b-form-input
            v-model="bnkaccname"
            class="text-uppercase"
            placeholder="joe bloggs"
            name="account-holder-name-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          label="Sort code"
          label-for="sort-code-edit"
        >
          <b-form-input
            v-model="bnksortcode"
            name="sort-code-edit"
            placeholder="xx-xx-xx"
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          label="Account number"
          label-for="account-no-edit"
        >
          <b-form-input
            v-model="bnkaccno"
            name="account-no-edit"
            placeholder="xxxxxxxx"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Reference"
          label-for="bacs-reference-edit"
        >
          <b-form-input
            v-model="bacsref"
            class="text-uppercase"
            name="bacs-reference-edit"
            placeholder="xxxxxxxx"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'BankDetailsInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
    bnkaccname: {
      get() {
        if (this.opInfo.bankdetails[0] !== undefined) {
          return this.opInfo.bankdetails[0].bnkaccname
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeBankAccountName', value)
      },
    },
    bnksortcode: {
      get() {
        if (this.opInfo.bankdetails[0] !== undefined) {
          return this.opInfo.bankdetails[0].bnksortcode
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeBankSortcode', value)
      },
    },
    bnkaccno: {
      get() {
        if (this.opInfo.bankdetails[0] !== undefined) {
          return this.opInfo.bankdetails[0].bnkaccno
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeBankAccNo', value)
      },
    },
    bacsref: {
      get() {
        if (this.opInfo.bankdetails[0] !== undefined) {
          return this.opInfo.bankdetails[0].bacsref
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setOperativeBacsRef', value)
      },
    },
  },
}
</script>

<style scoped>

</style>
