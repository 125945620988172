<template>
  <div>
    <b-card>
      <b-form @submit.prevent="saveOperative">
        <privateInfoSection
          :creating-new="operativeId < 0"
          :operative-id="operativeId"
        />
        <personalInfoSection />
        <contactInfoSection />
        <addressInfoSection />
        <tradingInfoSection />
        <sclservicesInfoSection />
        <bankDetailsInfoSection />
      </b-form>
    </b-card>
  </div>
</template>

<script>

import {
  BForm, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import privateInfoSection from '@/components/operatives/editorsections/PrivateInfoSection.vue'
import personalInfoSection from '@/components/operatives/editorsections/PersonalInfoSection.vue'
import contactInfoSection from '@/components/operatives/editorsections/ContactInfoSection.vue'
import addressInfoSection from '@/components/operatives/editorsections/AddressInfoSection.vue'
import tradingInfoSection from '@/components/operatives/editorsections/TradingInfoSection.vue'
import sclservicesInfoSection from '@/components/operatives/editorsections/SCLServicesInfoSection.vue'
import bankDetailsInfoSection from '@/components/operatives/editorsections/BankDetailsInfoSection.vue'

export default {
  name: 'OperativeEditorForm',
  components: {
    BForm,
    BCard,
    privateInfoSection,
    personalInfoSection,
    contactInfoSection,
    addressInfoSection,
    bankDetailsInfoSection,
    tradingInfoSection,
    sclservicesInfoSection,
  },
  directives: {
    Ripple,
  },
  props: {
    operativeId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async created() {
    this.$store.commit('app/resetOperativeValidation')
    if (this.operativeId > -1) {
      try {
        await this.$store.dispatch('app/getCurrentOperative', { operativeid: this.operativeId })
      } catch (err) {
        console.debug('Error occurred when calling API: ', err.message)
        throw err
      }
    } else {
      this.$store.dispatch('app/resetCurrentOperative')
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
