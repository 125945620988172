<template>
  <div>
    <div class="float-right mb-2">
      <b-button
        variant="primary"
        block
        type="submit"
        @click="onSaveOperative()"
      >
        Create Operative
      </b-button>
    </div>
    <b-card class="mt-3 full-width">
      <operativeEditorForm :operative-id="operativeId" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import operativeEditorForm from '@/components/operatives/OperativeEditorForm.vue'
import swal from 'sweetalert2'
import jwt from '@/auth/jwt/sclUseJwt'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    BButton,
    operativeEditorForm,
  },
  data() {
    return {
      operativeId: -1,
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
  created() {
    this.$store.dispatch('app/getTrades')
    if (this.$route.query.operativeid === undefined || this.$route.query.operativeid < 0) {
      this.opertiveId = -1
      this.$store.commit('app/resetCurrentOperative')
    } else {
      this.operativeId = Number(this.$route.query.operativeid)
    }
  },
  methods: {
    showMessage(iconType, titleText, messageText) {
      swal.fire({
        title: titleText,
        text: messageText,
        icon: iconType,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    createOperative() {
      const data = JSON.stringify(this.opInfo)
      jwt.axiosIns.post('/operative/0/', data)
        .then(response => {
          if (response.data.message !== undefined) {
            this.showMessage('error', 'Error', response.data.message)
          } else {
            this.showMessage('success', 'Success!', `Operative '${this.opInfo.firstname} ${this.opInfo.lastname}' has been created.`)
            this.$store.commit('app/resetCurrentOperative')
          }
        })
        .catch(e => {
          this.showMessage('error', 'Error', 'There was an error creating the Operative. Please review for missing fields')
          console.error(e.response)
        })
    },
    onSaveOperative() {
      if (this.operativeId > -1) {
        this.$store.dispatch('app/saveCurrentOperative', { operativeid: this.operativeId })
      } else {
        this.createOperative()
      }
    },
    onBackToPaymentsClick() {
      this.$router.go(-1) // Return to previous page
    },
  },
}
</script>

<style>

</style>
